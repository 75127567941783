
.slide-btn{
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: rgba(0, 0, 0, .3);
    }
}
.recover-slide{
    .swiper-slide{
        max-width: calc((100% - 10px) / 2);
    }
}
.contents-slide{
    .swiper-slide{
        max-width: calc((100% - 10px) / 2);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .recover-slide{
        .swiper-slide{
            max-width: calc((100% - 20px) / 3);
        }
    }
    .contents-slide{
        .swiper-slide{
            max-width: calc((100% - 20px) / 3);
        }
    }
}
@media (min-width:1024px){
    .recover-slide{
        .swiper-slide{
            max-width: calc((100% - 80px) / 5);
        }
    }
    .contents-slide{
        .swiper-slide{
            max-width: calc((100% - 40px) / 3);
        }
    }
}
@media (min-width:1200px){
}

