
.banner{
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc((200 / 1310) * 100%);
    &__inner{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.banner-slide{
    position: relative;
    .banner-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
        &--prev{
            left: 16px;
        }
        &--next{
            right: 16px;
        }
        &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
            background-color: rgba(0, 0, 0, .3);
        }
        &.v-btn.v-btn--icon.v-size--large{
            width: 40px;
            height: 40px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .banner-slide{
        position: relative;
        .banner-btn{
            &--prev{
                left: 40px;
            }
            &--next{
                right: 40px;
            }&.v-btn.v-btn--icon.v-size--large{
                width: 56px;
                height: 56px;
            }
        }
    }
}
@media (min-width:1200px){
}

