
.icon-hot {
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: #fa4205;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-weight: 500;
    color: #fff;
    text-align: center;
    font-size: 1.2rem;
    top: 10px;
    left: 10px;
    z-index: 5;
    border-radius: 50%;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .icon-hot {
        width: 55px;
        height: 55px;
        font-size: 1.6rem;
        top: 16px;
        left: 16px;
    }
}
@media (min-width: 1200px) {
}
