
.side-banner__right{
    display: none;
    max-width: 290px;
    margin: 0 auto 20px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .side-banner__inner{
        display: flex;
    }
    .side-banner__left{
        width: calc(100% - 290px);
    }
    .side-banner__right{
        display: block;
        width: 290px;
        padding: 60px 0 60px 40px;
        margin:auto;
    }
}
